<template>
  <div class="ds-container">
    <section class="ds-header">
      <div class="ds-title">
        <h2>Relatório de guias</h2>
        <span id="help-icon" class="icon-box">
          <HelpCircle class="icon stroke" />
        </span>
        <b-tooltip target="help-icon" placement="bottom">
          Visualize o relatório das guias cadastradas.
        </b-tooltip>
      </div>
      <!-- <div class="no-print">
          <b-button
            class="wh-button"
            variant="outline-primary"
            outline
            @click="print"
          >
            Imprimir
          </b-button>
        </div> -->
      <section class="buttons">
        <div class="btn-title-container">
          <b-button
            class="wh-button new-tiss"
            variant="primary"
            @click="openSetBatchModal"
            id="setInvoiceButton"
            :disabled="selectedGuides.length === 0"
          >
            Vincular guias na fatura
          </b-button>
          <b-tooltip
            target="setInvoiceButton"
            triggers="hover"
            placement="bottom"
          >
            Selecione as guias para vincular a uma fatura
          </b-tooltip>
          <b-button
            class="wh-button new-tiss"
            variant="primary"
            @click="openNewTissGuideModal"
          >
            <Plus class="icon-plus mr-2 my-auto" />
            Criar nova guia
          </b-button>
        </div>
      </section>
    </section>

    <section>
      <GuideReportContainer @selected-guides="setSelectedGuides" />
    </section>
  </div>
</template>

<script>
export default {
  components: {
    HelpCircle: () => import('@/assets/icons/help-circle.svg'),
    Plus: () => import('@/assets/icons/plus.svg'),
    GuideReportContainer: () =>
      import('@/layouts/InvoicingTiss/GuideReportContainer.vue')
  },
  data() {
    return {
      selectedGuides: []
    }
  },
  props: {
    print: Function
  },
  methods: {
    openNewTissGuideModal() {
      this.$bvModal.show('new-tiss-guide-modal')
    },
    openSetBatchModal() {
      this.$bvModal.show('set-invoice-modal')
    },
    setSelectedGuides(guides) {
      this.selectedGuides = guides
    }
  }
}
</script>

<style lang="scss" scoped>
.ds-container {
  padding: 30px 10px;
  .ds-header {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0 30px 0;
  }
  .ds-title {
    h2 {
      display: inline-flex;
      align-items: center;
      font-family: 'Red Hat Display';
      font-weight: 500;
      font-size: 18px;
    }

    .icon {
      height: 24px;
      width: 24px;
      margin-left: 5px;
      stroke: var(--neutral-500);
    }
  }
  .buttons {
    margin-left: auto;
    .new-tiss {
      display: flex;
      font-weight: 700;
      font-size: 16px;
      margin: 0 auto;
      .icon-plus {
        width: 16px;
        height: 16px;
        fill: var(--neutral-000) !important;
      }
    }
  }
  .btn-title-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    button {
      font-weight: bold;
    }

    .btn-primary {
      padding: 4px 15px;
    }

    .upload-icon {
      height: 22px;
      width: 22px;
      margin-right: 7px;
      margin-bottom: 3px;
      overflow: inherit;
      fill: var(--neutral-000);
    }
  }
}
</style>
